<template>
    <div class="text-center">
        <v-dialog
            v-model="dialog"
            width="500"
        >
            <template v-slot:activator="{ on, attrs }">
                <v-btn small color="primary" class="text-unset" v-on="on" :disabled="disabled">Buy ticket</v-btn>
            </template>

            <v-card>
                <v-card-title class="headline grey lighten-2">
                    Select quantity
                </v-card-title>

                <v-card-text>
                    <b-form-group
                        label="How many tickets do you want to buy"
                    >
                        <b-form-select
                            id="inline-form-custom-select-pref"
                            class="mb-2 mr-sm-2 mb-sm-0"
                            :options.sync="options"
                            :value="null"
                            v-model="quantity"
                        ></b-form-select>
                    </b-form-group>


                </v-card-text>

                <v-card-text>
                    Are you sure that you want to buy {{quantity}} tickets with {{ (quantity * price / htlEur).toFixed(2)}} of your {{ available|formatHtl }} HTL?
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        text
                        @click="dialog = false"
                        class="text-unset"
                    >
                        Back
                    </v-btn>
                    <v-btn v-if="shopItemTxtId!=null"
                        color="error"
                        text
                        @click="buyButNormal"
                        class="text-unset"
                    >
                        Buy {{ quantity }} tickets
                    </v-btn>
                    <v-btn v-else
                        color="error"
                        text
                        @click="buy"
                        class="text-unset"
                    >
                        Buy {{ quantity }} tickets
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import gql from "graphql-tag";
import {mapGetters} from "vuex";


export default {
    props: [
        'price',
        'disabled',
        'shopItemTxtId'
    ],
    data() {
        return {
            dialog: false,
            quantity: 0,
        }
    },
    computed: {
        ...mapGetters({
            available: 'user/available',
            htlEur: 'prices/htlEur',
            rate: 'prices/rate'
        }),
        options: function() {
            return[...Array(Math.ceil((this.available + 1) / 1e7 / this.price * this.htlEur)).keys()]
        }
    },
    methods: {
        buy() {
            this.$apollo.mutate({
                // Query
                mutation: gql`mutation($token:String!,$quantity:Int!){buyTicket(token:$token,quantity:$quantity)}`,
                // Parameters
                variables: {
                    token: this.rate,
                    quantity: this.quantity
                },
            }).then(() => {
                this.$router.go(0);
            })
        },
        //infotrend
        buyButNormal() {
            this.$apollo.mutate({
                // Query
                mutation: gql`mutation($token:String!,$quantity:Int!,$shopItemTxtId:String!){buyTicketButNormal(token:$token,quantity:$quantity,shopItemTxtId:$shopItemTxtId)}`,
                // Parameters
                variables: {
                    token: this.rate,
                    quantity: this.quantity,
                    shopItemTxtId: this.shopItemTxtId
                },
            }).then(() => {
                this.$router.go(0);
            })
        }
    },
}
</script>

